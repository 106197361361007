@use '../../../../../modules/shared/util-styling/mixins';

body {
  --font-family-primary: var(
    --mdrt-font-families-nunito-sans
  ); /* Needed because of Next Js generated font family name  */

  --font-family-secondary: var(
    --mdrt-font-families-merriweather
  ); /* Needed because of Next Js generated font family name  */

  --font-family-table-data: monospace;
  --font-weight-regular: var(--mdrt-font-weights-nunito-sans-2);
  --font-weight-medium: var(--mdrt-font-weights-nunito-sans-1);
  --font-weight-bold: var(--mdrt-font-weights-merriweather-0);
  --font-weight-extra-bold: var(--mdrt-font-weights-merriweather-3);
  --font-size-l: var(--mdrt-font-size-4);
  --font-size-m: var(--mdrt-font-size-3);
  --font-size-s: var(--mdrt-font-size-2);
  --font-size-xs: var(--mdrt-font-size-1);
  --font-size-2xs: var(--mdrt-font-size-0);
  --font-size-body: 1.8rem;

  /* stylelint-disable scss/operator-no-newline-after */
  /* stylelint-disable custom-property-empty-line-before */

  --mdrt-headline-4: var(--font-weight-bold) var(--mdrt-font-size-3) / var(--mdrt-line-heights-3)
    var(--font-family-secondary); /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-headline-3: var(--font-weight-bold) var(--mdrt-font-size-4) / var(--mdrt-line-heights-2)
    var(--font-family-secondary); /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-headline-2: var(--font-weight-bold) var(--mdrt-font-size-5) / var(--mdrt-line-heights-1)
    var(--font-family-secondary); /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-headline-1: var(--font-weight-bold) var(--mdrt-font-size-6) / var(--mdrt-line-heights-0)
    var(--font-family-secondary); /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-title-1: var(--mdrt-font-weights-nunito-sans-1) var(--mdrt-font-size-4) /
    var(--mdrt-line-heights-2) var(--font-family-primary);
  --mdrt-title-2: var(--mdrt-font-weights-nunito-sans-1) var(--mdrt-font-size-3) /
    var(--mdrt-line-heights-3) var(--font-family-primary);
  --mdrt-title-3: var(--mdrt-font-weights-nunito-sans-1) var(--mdrt-font-size-2) /
    var(--mdrt-line-heights-5) var(--font-family-primary);
  --mdrt-title-4: var(--mdrt-font-weights-nunito-sans-1) var(--mdrt-font-size-1) /
    var(--mdrt-line-heights-5) var(--font-family-primary);
  --mdrt-body-1: var(--mdrt-font-weights-nunito-sans-2) var(--mdrt-font-size-4) /
    var(--mdrt-line-heights-2) var(--font-family-primary);
  --mdrt-body-2: var(--mdrt-font-weights-nunito-sans-2) var(--mdrt-font-size-3) /
    var(--mdrt-line-heights-0) var(--font-family-primary);
  --mdrt-body-3-short: var(--mdrt-font-weights-nunito-sans-2) var(--mdrt-font-size-2) /
    var(--mdrt-line-heights-1) var(--font-family-primary);
  --mdrt-body-3-long: var(--mdrt-font-weights-nunito-sans-2) var(--mdrt-font-size-2) /
    var(--mdrt-line-heights-5) var(--font-family-primary);
  --mdrt-body-4: var(--mdrt-font-weights-nunito-sans-2) var(--mdrt-font-size-1) /
    var(--mdrt-line-heights-5) var(--font-family-primary);

  /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */

  /* stylelint-enable scss/operator-no-newline-after */

  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-28: 28px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --container-max-width: 1440px;
  --container-min-width: 320px;
  --section-content-max-width: 1192px;
  --animation-duration: 0.2s;
  --z-index-toast: 1200;
  --z-index-loader-overlay: 1100;
  --z-index-modal: 1000;
  --z-index-sticky: 800;
  --z-index-navbar: 500;
  --z-index-inner-loader-overlay: 400;
  --z-index-dropdown: 300;
  --z-index-content: 100;
  --z-index-background: 1;

  /* stylelint-disable scss/operator-no-newline-after */

  /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-special-text-button-1: var(--mdrt-font-weights-merriweather-3) var(--mdrt-font-size-3) /
    var(--mdrt-line-heights-7) var(--mdrt-font-families-merriweather);

  /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-special-text-button-2: var(--mdrt-font-weights-merriweather-3) var(--mdrt-font-size-2) /
    var(--mdrt-line-heights-1) var(--mdrt-font-families-merriweather);

  /* Needed because of  this issue: https://github.com/amzn/style-dictionary/issues/819  */
  --mdrt-special-text-button-3: var(--mdrt-font-weights-merriweather-3) var(--mdrt-font-size-1) /
    var(--mdrt-line-heights-6) var(--mdrt-font-families-merriweather);

  /* stylelint-enable scss/operator-no-newline-after */

  --button-font: var(--mdrt-special-text-button-1);
  --button-border-size: var(--mdrt-border-thickness-01);
  --search-box-shadow-color: #000;
  --temp-color-neutral-60: #7c7c7c;
  --temp-color-neutral-dark: #252525;
  --temp-color-neutral-dark-40: #979797;
  --temp-color-neutral-dark-transparent-40: rgb(37 37 37 / 40%);
  --temp-color-neutral-gray-80: #515151;
  --temp-color-neutral-light-40: #d8d8d8;
  --temp-color-neutral-light-20: #eaeaea;
  --temp-color-neutral-light-10: #f8f8f8;
  --temp-color-neutral-light: #fff;
  --temp-color-primary-tint-20: #d1dded;
  --temp-color-primary-tint-darker: #134282;
  --temp-color-primary-tint-80: #4675b5;
  --temp-color-primary-fill-80: #1853a3cc;
  --color-black: #000;
  --temp-color-white-opacity-30: #ffffff4d;

  /* Missing tokens */
  --mdrt-color-backgrounds-bg-primary-fill-missing: #18365f;

  --nav-button-group-bg-surface: var(--mdrt-color-backgrounds-bg-surface);
  --nav-button-group-border-fill: var(--mdrt-color-borders-dark-01);
  --nav-button-group-border-radius: var(--mdrt-corner-radius-xl);
  --nav-button-group-border-gap: var(--spacing-16);
  --nav-button-group-border-padding: var(--spacing-8);

  --badge-tertiary: #ffcf00;
  --badge-tertiary-text-color: #1c1c1c;

  --select-caret-space-size: var(--spacing-40);

  --overlay-primary-fill: rgb(0 0 0 / 50%);
  --overlay-secondary-fill: #1c1c1c99;
  --overlay-tertiary-fill: #1c1c1cb2;
  --overlay-text: #fff;

  /* renamed variables */
  --chip-font: var(--mdrt-title-3);
  --badge-font: var(--mdrt-title-4);
  --card-elevation-01: var(--mdrt-elevation-01);
  --card-elevation-02: var(--mdrt-elevation-02);
  --input-font-size: var(--font-size-s);
  --input-font-weight: var(--font-weight-regular);
  --input-line-height: 1.375;
  --input-line-height-2: 1.875;
  --alert-font-style: var(--mdrt-body-3-long);
  --field-error-message-color: var(--mdrt-color-text-on-light-error);

  /* global variables */
  --app-header-height-primary: 80px;
  --app-header-height-secondary: 96px;
  --content-max-width: 1240px;
  --content-max-width-wide: 2800px;
  --mobile-drawer-actions-height: 48px;
  --mobile-drawer-actions-width: 48px;
  --player-icon-size: 24px;
  --app-sub-menu-height: 44px;
  --search-results-footer-height: 93px;
  --toast-max-width: 452px;

  /* responsive variables */
  --container-spacing-inline: var(--spacing-16);

  @include mixins.breakpoint('small') {
    --container-spacing-inline: var(--spacing-24);
  }
}
